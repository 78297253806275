<div fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="login-card">

        <mat-card-title>
            Bienvenido <small style="color: red" *ngIf="mensaje">{{mensaje}}</small> <br>
        </mat-card-title>

        <mat-card-content  class="bodyboard" [formGroup]="login">
            <div class="row">

                <mat-form-field appearance="outline">
                    <mat-label>Rut:</mat-label>
                    <input matInput formControlName="rut">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>Token:</mat-label>
                    <input matInput formControlName="token" (keyup.enter)="loguear()">
                </mat-form-field>
            </div>
        </mat-card-content>


        <mat-card-footer fxLayoutAlign="center end" class="bodyboard">
            <br>
            <button mat-raised-button color="primary" (click)="loguear()" [disabled]="!login.valid">Ingresar</button>
            <br>
        </mat-card-footer>

    </mat-card>
</div>