import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @ViewChild('sidenav') elmenu: MatSidenav;

  links = ['welcome', 'referencias'];

  constructor() { }
  
  
  
  ngOnInit(): void {
  }

  public abreMenu(){
    this.elmenu.toggle();
  }

}
