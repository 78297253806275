import { Component, OnInit } from '@angular/core';
import { EducationsService } from 'src/app/educations.service';


@Component({
  selector: 'app-cargar-plani-anual',
  templateUrl: './cargar-plani-anual.component.html',
  styleUrls: ['./cargar-plani-anual.component.scss']
})
export class CargarPlaniAnualComponent implements OnInit {

  selected = 'objetivos';
  curso:any = false;
  asignatura:any = false;
  asignaturas: any = false;
  unidades: any = false;
  uni:any = false;
  cursos = [];
  planificaciones = [];
  planificacion: any = false;
 

  constructor(private _ede:EducationsService) { }

  ngOnInit(): void {  
    this._ede.getCursos().subscribe((cursos:any)=>{
      this.cursos = cursos;
    })
  }

  buscarAsignaturas(curso){
    this.curso = curso;
    this.unidades = false;
    this.planificacion = false;
    this._ede.getAsignaturas(curso).subscribe((asignaturas)=>{
        this.asignaturas = asignaturas;
    });
  }

  buscarPlanis(curso, asignatura){
    this.asignatura = asignatura;
    this._ede.getPlanis(curso, asignatura).subscribe((planis: any)=>{
      this.planificaciones = planis.planificaciones;
      this.unidades = planis.unidades;
      this.uni = this.unidades[0];
      this.planificacion = false;
    });
  }

  getOAs(unidad){
    let planis = this.planiUnis(unidad);
    return [... new Set(planis.map(a=>a.id_oa_generico))].sort();
  }

  recarga(plani){
    this.planificacion = false;
    setTimeout(()=>{
      this.planificacion = plani;
    },1)
  }

  ver(algo){
    let id = algo.index;
    if(this.planificacion.new){
      this.planificacion = false;
    }
    this.uni = this.unidades[id]
  }
 
  planiUnis(uni){
    let _unis = [];
    this.planificaciones.forEach(plani => {
      if(plani.n_unidad == uni){
        _unis.push(plani);
      }
    });
    return _unis;
  }

  nueva(){
    this.planificacion = false;
    setTimeout(()=>{
      this.planificacion = {
        new: true,
        unidad: this.uni.nombre,
        n_unidad: this.uni.numero,
        idasgnatura: this.planificaciones[0].idasgnatura,
        curso: this.planificaciones[0].curso,
        tiempo: '6hrs',
        aprobacion: 1,
        observacion_utp: 'sin observacion',
      };
    },1)
  }
 

}
