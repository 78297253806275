import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  auth = localStorage.getItem('auth') == null ? false : true;

  sections = [
    {
      section: 'planificaciones',
      img: 'organizations',
      url: 'planificaciones'
    },
    {
      section: 'personas', 
      img: 'Personas',
      url: 'personsas'
    },
    {
      section: 'organizaciones', 
      img: 'organizations',
      url: 'organizaciones'
    },
    {
      section: 'establecimientos', 
      img: 'establecimientos',
      url: 'establecimientos'
    },
    {
      section: 'validador',
      img: 'cursos',
      url: 'validador'
    },
    {
      section: 'referencias',
      img: 'cursos',
      url: 'referencias'
    },
    {
      section: 'cursos', 
      img: 'cursos',
      url: 'cursos'
    },
    {
      section: 'calendarios', 
      img: 'calendarios',
      url: 'calendarios'
    },
  ];

  constructor(private _router: Router) {
  }
  
  ngOnInit(): void {
   if (!this.auth) {
     this._router.navigate(['login']);
   }
  }

}
