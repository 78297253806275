import { Component, OnInit } from '@angular/core';
import { EducationsService } from 'src/app/educations.service';

@Component({
  selector: 'app-establecimientos',
  templateUrl: './establecimientos.component.html',
  styleUrls: ['./establecimientos.component.scss']
})
export class EstablecimientosComponent implements OnInit {
  orgs;
  modalidades;
  jornadas;
  niveles;
  ramas;
  sectores;
  especialidades;
  tipos;
  ensenanzas;
  grados;
  cursos;
  asignaturas;

  constructor(private ede: EducationsService) { }

  ngOnInit(): void {
    this._orgs();
  }

  _orgs(){
    let rbd = 3717
     this.ede.getRbdOrgs(rbd).subscribe((orgs)=>{
       this.orgs = orgs;
     });
  }

  cambio(n){
    console.log(n);
    if(n < 11){
      this.asignaturas = false;
    }
    if(n < 10){
      this.cursos = false;
    }
    if(n < 9){
      this.grados = false;
    }
    if(n < 8){
      this.ensenanzas = false;
    }
    if(n < 7){
      this.tipos = false;
    }
    if(n < 6){
      this.especialidades = false;
    }
    if(n < 5){
      this.sectores = false;
    }
    if(n < 4){
      this.ramas = false;
    }
    if(n < 3){
      this.niveles = false;
    }
  
  }

}
