import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { CargarPlaniAnualComponent } from './components/planificaciones/planiAnual/cargar-plani-anual/cargar-plani-anual.component';
import { PlanificacionesComponent } from './components/planificaciones/planificaciones.component';
import { ReferenciasComponent } from './components/referencias/referencias.component';
import { ValidatorComponent } from './components/validator/validator.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { EstablecimientosComponent } from './establecimientos/establecimientos/establecimientos.component';

const routes: Routes = [
  { path: '',   redirectTo: 'welcome', pathMatch: 'full' },
  {path: 'login', component: LoginComponent ,},
  {path: 'welcome', component: WelcomeComponent},
  {path: 'referencias', component: ReferenciasComponent},
  {path: 'validador', component: ValidatorComponent},
  {path: 'establecimientos', component: EstablecimientosComponent},
  {path: 'planificaciones', component: PlanificacionesComponent},
  {path: 'planificaciones/cargar', component: CargarPlaniAnualComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
