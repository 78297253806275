<mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon routerLink="/">home</mat-icon>
    </button>
    <span routerLink="/">EdeDataEduc</span>
    <span class="example-spacer"></span>
  
    <app-validator *ngIf="auth"></app-validator>
    <button mat-icon-button class="example-icon" (click)="cerrar()" >
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar>