import { getLocaleEraNames } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { EducationsService } from 'src/app/educations.service';

@Component({
  selector: 'app-plani-anual',
  templateUrl: './plani-anual.component.html',
  styleUrls: ['./plani-anual.component.scss']
})
export class PlaniAnualComponent implements OnInit {

  @Input() planificacion;
  @Output() evento = new EventEmitter();
  plani: FormGroup;
  cargando = false;

  fOptions = {
    heightMin: 250,
    heightMax: 400,
    placeholderText: 'insert text here...', 
    charCounterCount: true, 
    toolbarButtons: ['bold', 'italic', 'underline', '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor' , '|', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink', 'insertImage', 'insertTable', '|', 'specialCharacters', 'insertHR', 'clearFormatting', '|', 'undo', 'redo','trackChanges',],
    key: "gVG3C-8C1A1A4B1B3C2unjxlkkerB1pxiB3B9B6C5C2C4C3H3G3I3==",
  }
  constructor(private _ede: EducationsService) { }

  ngOnInit(): void {
    this.plani = new FormGroup({
      id_oa_generico: new FormControl(this.planificacion.id_oa_generico),
      n_unidad: new FormControl(this.planificacion.n_unidad),
      unidad: new FormControl(this.planificacion.unidad),
      id_aprendizaje_esp_tp: new FormControl(this.planificacion.id_aprendizaje_esp_tp),
      n_semanas: new FormControl(this.planificacion.n_semanas),
      n_evaluaciones: new FormControl(this.planificacion.n_evaluaciones),
      idasgnatura: new FormControl(this.planificacion.idasgnatura),
      curso: new FormControl(this.planificacion.curso),
      tipo_plani: new FormControl(this.planificacion.tipo_plani),
      aprendizajes_esperados: new FormControl(this.planificacion.aprendizajes_esperados),
      contenidos: new FormControl(this.planificacion.contenidos),
      objetivos: new FormControl(this.planificacion.objetivos),
      conocimientos_previos: new FormControl(this.planificacion.conocimientos_previos),
      actitudes: new FormControl(this.planificacion.actitudes),
      tiempo: new FormControl(this.planificacion.tiempo),
      indicadores: new FormControl(this.planificacion.indicadores),
      aprendizaje_oat: new FormControl(this.planificacion.aprendizaje_oat),
      aprobacion: new FormControl(this.planificacion.aprobacion),
      observacion_utp: new FormControl(this.planificacion.observacion_utp),
      agno: new FormControl(2022),
      semestre: new FormControl(1),
    });
  }

  mandale(){
    if(this.planificacion.new){
      this.store();
    }else{
      this.update();
    }
  }

  update(){
    this.cargando = true;
    let keys = this.plani.controls; 
    let cambios = {
      id: this.planificacion.id,
    };
    Object.keys(keys).forEach(key => {
      if(keys[key].dirty){
        cambios[key] = keys[key].value; 
      }
    });
    this._ede.updatePlani(cambios.id, cambios).subscribe((plani)=>{
      this.planificacion = plani;
      this.cargando = false;
    }); 
  }

  store(){
    this.cargando = true;
    let _pl = this.plani.value;
    console.log(_pl);
    this._ede.storePlani(_pl).subscribe((plani)=>{
          this.cargando = false;
          this.evento.emit();
      })
  }

}

