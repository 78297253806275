<div class="ref-box">
  <div fxLayout="row" >
    <div fxFlex="20" align="rigth" style="margin-left: 2%;"> 
      <h2>Referencia: </h2> 
    </div>
    <div fxFlex="41" > 
      <h2 *ngIf="referencia">{{refSelect.value ? refSelect.value.substring(8).replaceAll('_', ' ') : '' | titlecase}}</h2> 
    
    </div>
    <div fxFlex="8">
      <button mat-raised-button color="primary" (click)="decretos()">Decretos</button>
    </div>
    <div fxFlex="20"> 
      <mat-form-field appearance="fill">
        <mat-label>Seleccionar referencia</mat-label>
        <mat-select #refSelect (selectionChange)="getReference($event.value)">
          <mat-option *ngFor="let ref of referencias" [value]="ref.url" [disabled]="excepciones.includes(ref.url)">
            {{ref.nombre.replaceAll('_', ' ') | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
  
  <div *ngIf="!cargando && !excepciones.includes(url)">
    <app-ref [ref]="ref" [uri]="url" *ngFor="let ref of referencia"></app-ref>
  </div>
  <app-decretos [uri]="url" *ngIf="!cargando && excepciones.includes(url)"></app-decretos>
</div>

  
  
  

  
  