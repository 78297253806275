import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EducationsService } from 'src/app/educations.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements OnInit {

  constructor(private http: HttpClient, private ede: EducationsService) { }

  @Input() rut? = '16.611.642-2';
  fToken: FormGroup;
  token: FormControl;
  validando = {
    doing: false,
    token: false,
    time: 0,
    icon: 'fingerprint',
  }; 
  ngOnInit(): void {
    this.cuantoQueda();
    this.fToken = new FormGroup({
      token: new FormControl('', [ Validators.required]),
    })

  }

  cuantoQueda(){
    setInterval(()=>{
        if(this.validando.time > 0){
            this.validando.time--;
        }else{
          if(this.validando.token){
            this.validando.icon = 'fingerprint';
            this.fToken.controls.token.enable();
            this.fToken.controls.token.setValue('');
            this.validando.token = false;
          }
        }
    }, 1000)
}
 
 validar(){
    let otp = this.fToken.controls['token'].value;
    this.validando.doing = true; 
    this.ede.validarToken(otp, this.rut).subscribe((respuesta:any)=>{
      
      if(!respuesta.OTPVERIFY){
        this.validando.icon = 'error_outline';
        setTimeout(()=>{
          this.validando.icon = 'fingerprint';
        }, 1000);
      }
      if(respuesta.OTPVERIFY){
        this.validando.icon  = 'schedule';
        this.validando.token = otp;
        this.fToken.controls.token.disable();
        this.validando.time = 30;
      }
      
    })
  }

}
