
<div *ngIf="orgs" class="conte">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{orgs.Name | titlecase}}</mat-card-title>
            <mat-card-subtitle>{{orgs.shortName}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <br>
            Modalidades: <br>
            <mat-button-toggle-group name="modalidads" aria-label="Font Style" >
                <mat-button-toggle *ngFor="let modalidad of orgs.modalidades" value="{{modalidad}}" (click)="jornadas = modalidad.jornadas; cambio(2)" >{{modalidad.Name}}</mat-button-toggle>               
            </mat-button-toggle-group>
            <hr>
            <div *ngIf="jornadas">
                Jornadas: <br>
                <mat-button-toggle-group name="jornas" aria-label="Jornadas" >
                    <mat-button-toggle *ngFor="let jornada of jornadas" value="{{jornada}}" (click)="niveles = jornada.niveles; cambio(3)" >{{jornada.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>
            <div *ngIf="niveles">
                Niveles: <br>
                <mat-button-toggle-group name="nivels" aria-label="Niveles" >
                    <mat-button-toggle *ngFor="let nivel of niveles" value="{{nivel}}" (click)="ramas = nivel.ramas; cambio(4)"  >{{nivel.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>

            <div *ngIf="ramas">
                Ramas: <br>
                <mat-button-toggle-group name="rams" aria-label="Ramas" >
                    <mat-button-toggle *ngFor="let rama of ramas" value="{{rama}}" (click)="sectores = rama.sectores; cambio(5)" >{{rama.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>

            <div *ngIf="sectores">
                Sectores: <br>
                <mat-button-toggle-group name="sectors" aria-label="Sectores" >
                    <mat-button-toggle *ngFor="let sector of sectores" value="{{sector}}" (click)="especialidades = sector.especialidades; cambio(6)" >{{sector.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>

            <div *ngIf="especialidades">
                Especialidades: <br>
                <mat-button-toggle-group name="especials" aria-label="Especialidades" >
                    <mat-button-toggle *ngFor="let especialidad of especialidades" value="{{especialidad}}" (click)="tipos = especialidad.tipos; cambio(7)" >{{especialidad.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>

            <div *ngIf="tipos">
                Tipos de Curso: <br>
                <mat-button-toggle-group name="tips" aria-label="Tipos" >
                    <mat-button-toggle *ngFor="let tipo of tipos" value="{{tipo}}" (click)="ensenanzas = tipo.ensenanzas; cambio(8)" >{{tipo.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>

            <div *ngIf="ensenanzas">
               Niveles de enseñanza: <br>
                <mat-button-toggle-group name="ens" aria-label="Ensenanzas" >
                    <mat-button-toggle *ngFor="let ensenanza of ensenanzas" value="{{ensenanza}}" (click)="grados = ensenanza.grado; cambio(9)" >{{ensenanza.Name}}</mat-button-toggle>               
                </mat-button-toggle-group>
                <hr>
            </div>

            <div *ngIf="grados">
                Grados: <br>
                 <mat-button-toggle-group name="grads" aria-label="Grados" >
                     <mat-button-toggle *ngFor="let grado of grados" value="{{grado}}" (click)="cursos = grado.cursos; ; cambio(10)" >{{grado.Name}}</mat-button-toggle>               
                 </mat-button-toggle-group>
                 <hr>
             </div>

             <div *ngIf="cursos">
                Cursos: <br>
                 <mat-button-toggle-group name="curs" aria-label="Cursos" >
                     <mat-button-toggle *ngFor="let curso of cursos" value="{{curso}}" (click)="asignaturas = curso.asignaturas; cambio(11)" >{{curso.Name}}</mat-button-toggle>               
                 </mat-button-toggle-group>
                 <hr>
             </div>

             <div *ngIf="asignaturas">
                Asignaturas: <br>
                <mat-grid-list cols="2" rowHeight="5:1">
                    <mat-card  *ngFor="let asignatura of asignaturas" value="{{asignatura}}">{{asignatura.Name}}</mat-card >               
                </mat-grid-list>
                 <hr>
             </div>

        </mat-card-content>
    </mat-card>
</div>
