
    <div class="">
        <h2>OA_{{planificacion.id_aprendizaje_esp_tp}} </h2>
    </div>
    <div class="row g-5" [formGroup]="plani">
        <div class="col-1">
            <label for="">Priorizacion</label>
            <input type="text" class="form-control" placeholder="" formControlName="id_oa_generico" >
        </div>
        <div class="col-1">
            <label for="">Unidad</label>
            <input type="text" class="form-control" placeholder="" formControlName="n_unidad">
        </div>
        <div class="col-2">
            <label for="">Objetivo Aprendizaje</label>
            <input type="text" class="form-control" placeholder="" formControlName="id_aprendizaje_esp_tp">
        </div>
        <div class="col-2">
            <label for="">N° de Semanas</label>
            <input type="number" class="form-control" placeholder="" formControlName="n_semanas">
        </div>
        
        <div class="col-2">
            <label for="">N° Evaluaciones</label>
            <input type="text" class="form-control" placeholder="" formControlName="n_evaluaciones">
        </div>
        <div class="col-2">
            <label for="">Asignatura</label>
            <input type="text" class="form-control" placeholder="" formControlName="idasgnatura">
        </div>
   
    <hr>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab class="tabito" label="Objetivo Aprendizaje">
            <div [froalaEditor]="fOptions" formControlName="aprendizajes_esperados"></div>
        </mat-tab>
        <mat-tab class="tabito" label="Indicadores">
            <div [froalaEditor]="fOptions" formControlName="contenidos"></div>
        </mat-tab>
        <mat-tab class="tabito" label="Conocimientos">
            <div [froalaEditor]="fOptions"  formControlName="objetivos" >
            </div>
        </mat-tab>
        <mat-tab class="tabito" label="Habilidades">
            <div [froalaEditor]="fOptions" formControlName="conocimientos_previos"></div>
        </mat-tab>

        <mat-tab class="tabito" label="Actitud (OG)">
            <div [froalaEditor]="fOptions" formControlName="actitudes"></div>
        </mat-tab>
        <mat-tab class="tabito" label="Actividad de aprendizaje">
            <div [froalaEditor]="fOptions" formControlName="indicadores"></div>
        </mat-tab>
        <mat-tab class="tabito" label="Estrategia">
            <div [froalaEditor]="fOptions" formControlName="aprendizaje_oat"></div>
        </mat-tab>
    </mat-tab-group>
</div>
    <br>
    <button mat-raised-button color="primary" [disabled]="plani.pristine" (click)="mandale()">Guardar<mat-icon *ngIf="cargando"><span class="material-icons">
        more_horiz
        </span></mat-icon></button>
