<div class="centrar">
    <h2>Cargar Planificaciones Anuales</h2>
    <mat-card>
        <mat-form-field appearance="fill">
            <mat-label>Seleccione un curso</mat-label>
            <mat-select #cur (selectionChange)="buscarAsignaturas(cur.value)">
                <mat-option [value]="curso" *ngFor="let curso of cursos">{{curso}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="asignaturas" class="asi">
            <mat-label>Seleccione una asignatura</mat-label>
            <mat-select #asi (selectionChange)="buscarPlanis(cur.value, asi.value)">
                <mat-option>None</mat-option>
                <mat-option [value]="asignatura.id" *ngFor="let asignatura of asignaturas">({{asignatura.id}})
                    {{asignatura.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card>
</div>
<div class="centrar" *ngIf="unidades">
    <h2>Unidades</h2>
    <mat-card>
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="ver($event)" >
            <mat-tab [label]="unidad.numero"*ngFor="let unidad of unidades" style="min-height: 400px">
                <div class="">
                    <h3 [innerHTML]="unidad.nombre"></h3>
                </div>
                <pre>
                    {{uni | json}}
                </pre>
                <br>
                <button mat-raised-button class="bot" *ngFor="let plani of planiUnis(unidad.numero)"
                    [color]="planificacion.id == plani.id? 'primary': 'default'" (click)="recarga(plani)">
                    OA_{{plani.id_aprendizaje_esp_tp}}</button>
                <hr>
                <div class="bot">
                    <button mat-raised-button [color]="planificacion.new?'primary':'default'" (click)="nueva()">
                        <mat-icon>description</mat-icon>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Todas">
                <h2>Todas</h2>
                <button mat-raised-button class="bot" *ngFor="let plani of planificaciones"
                    [color]="planificacion.id == plani.id? 'primary': 'default'" (click)="recarga(plani)">
                    OA_{{plani.id_aprendizaje_esp_tp}}</button>
                <br>
            </mat-tab>
        </mat-tab-group>
        <app-plani-anual [planificacion]="planificacion" (evento)="planiUnis()" *ngIf="planificacion"></app-plani-anual>
    </mat-card>
</div>