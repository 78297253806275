<div class="conten">
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones/cargar">Cargar Planificaciones anuales TP</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
    <button mat-stroked-button color="primary" class="bot" routerLink="/planificaciones">Subir Guias</button>
</div>