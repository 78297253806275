import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { EducationsService } from 'src/app/educations.service';
import { NavComponent } from '../nav/nav.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [NavComponent],
})
export class LoginComponent implements OnInit {

  private clave = 'ede3783';
  ruts = ['16611642-2', '8601891-8', '16659230-5'];
  hide = true;
  mensaje: any;
  auth = localStorage.getItem('auth') == null ? false : true;
  login: FormGroup;
  constructor(private _router: Router, private ind: AppComponent, private ede: EducationsService ) { }

  ngOnInit(): void {

    this.login = new FormGroup({
      rut: new FormControl('',[ Validators.required],),
      token: new FormControl('',[ Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    })

    if(this.auth){
      this._router.navigate(['welcome']);
    }
  }



  loguear(){

    console.log(this.login.value.rut);
    this.ede.validarToken(this.login.value.token, this.login.value.rut).subscribe((respuesta:any)=>{
      console.log(respuesta.OPTVERIFY);
      if(respuesta.OTPVERIFY && this.ruts.includes(respuesta.RUT)){
        localStorage.setItem('auth', 'autorizado');
        this.ind.auth = true;
        this._router.navigate(['welcome']);
      } else {
        this.mensaje = 'Token incorrecto';
        setTimeout(()=>{
          this.mensaje = false;
        }, 1200)
      }
    })
  
  }

}
