<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="60">

    <mat-accordion>
      
      <mat-expansion-panel hideToggle *ngFor="let cod of cod_ens ">
        <mat-expansion-panel-header>
          <mat-panel-title>
           {{cod}}
          </mat-panel-title>
          <mat-panel-description>
            {{cods[cod][0].COD_ENSE_AGRUPADO }}
          </mat-panel-description>
        </mat-expansion-panel-header>
          <app-rama [decretos]="cods[cod]"></app-rama>
      </mat-expansion-panel>
     
  </mat-accordion>
</div>
</div>

