import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from 'src/app/services/sidenav.service';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  
})


export class NavComponent implements OnInit {

  @Input() auth;

  constructor(private side: SidenavService, private router: Router) { }

  ngOnInit(): void {
  }

  elopen(){
    this.side.toggle();
  }
  authen(){
    this.auth = true;
  }
  cerrar(){
    localStorage.removeItem('auth');
    this.auth = false;
    this.router.navigate(['login']);
  }

}
