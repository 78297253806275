import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EducationsService } from 'src/app/educations.service';

@Component({
  selector: 'app-referencias',
  templateUrl: './referencias.component.html',
  styleUrls: ['./referencias.component.scss']
})
export class ReferenciasComponent implements OnInit {

  
  referencia: any;
  referencias = [];
  cargando = false;
  url;
  excepciones = [ 'api/ref/grade_level' ];



  constructor(private _ede:EducationsService, private _http: HttpClient) {
    this._ede.getRefs().subscribe((refs:[]) => {
      refs.forEach((ref:string) => {
        const oRef = {nombre: ref.slice(8), url: ref};
        this.referencias.push(oRef);
      });
    });
  }

  getReference(url){
      this.cargando = true;
      this.url = url;
      this._ede.getReference(url).subscribe((response=>{
        this.referencia = response;
        this.cargando = false;
      }));
  }

  decretos(){
    this.getReference(this.referencias[18].url);

  }


  ngOnInit(): void {
    
  }

}
