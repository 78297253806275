<mat-card class="ref-card" fxLayout="row" *ngIf="ref">
        <div fxFlex="35" class="card-left">

            <div  *ngIf="ref.code" fxLayout="row" class="card-dentro">
                <div fxFlex="40">
                    <strong>Code:</strong>
                </div>
                <div fxFlex="50">

                    {{ref.code}}
                </div>
            </div>
            <div  *ngIf="ref.definition" fxLayout="row" class="card-dentro">
                <div fxFlex="40">
                    <strong>Definition:</strong>
                </div>
                <div fxFlex="50">

                    {{ref.definition | json}}
                </div>
            </div>
            <div  *ngIf="ref.description" fxLayout="row" class="card-dentro">
                <div fxFlex="40">
                    <strong>Description:</strong>
                </div>
                <div fxFlex="50">

                    {{ref.description}}
                </div>
            </div>
        </div>
        
        <hr>
        
        <div fxFlex="60" class="card-left" >
            <form [formGroup]="formes">
                <div *ngIf="ref.code" fxLayout="row" class="card-dentro">
                    <div fxFlex="20">
                        <strong>Código: </strong> 
                    </div>
                    <div fxFlex="60">
                        <mat-form-field class="example-full-width" appearance="fill">
                            
                            <input matInput type="text" formControlName="code_es" [placeholder]="'traducción'">
                        </mat-form-field>
                        <mat-icon *ngIf="ref.code_es || ref.code_es != null " style="color: rgb(14, 95, 14)">check_circle</mat-icon>
                        <mat-icon *ngIf="!ref.code_es || ref.code_es === null " style="color: rgb(88, 3, 3)">highlight_off</mat-icon>
                    </div>
                </div>
                <div *ngIf="ref.definition" fxLayout="row" class="card-dentro">
                    <div fxFlex="20">
                        <strong>Definición: </strong> 
                    </div>
                    <div fxFlex="60">
                        <mat-form-field class="example-full-width" appearance="fill">
                            
                            <input matInput type="text" formControlName="definition_es" [placeholder]="'traducción'">
                        </mat-form-field>
                        <mat-icon *ngIf="ref.definition_es || ref.definition_es != null " style="color: rgb(14, 95, 14)">check_circle</mat-icon>
                        <mat-icon *ngIf="!ref.definition_es || ref.definition_es === null " style="color: rgb(88, 3, 3)">highlight_off</mat-icon>
                    </div>
                </div>
                <div *ngIf="ref.description" fxLayout="row" class="card-dentro">
                    <div fxFlex="20">
                        <strong>Descripción: </strong> 
                    </div>
                    <div fxFlex="60">
                        <mat-form-field class="example-full-width" appearance="fill">
                            
                            <input matInput type="text" formControlName="description_es" [placeholder]="'traducción'">
                        </mat-form-field>
                        <mat-icon *ngIf="ref.description_es && ref.description_es != null " style="color: rgb(14, 95, 14)">check_circle</mat-icon>
                        <mat-icon *ngIf="!ref.description_es && ref.description_es === null " style="color: rgb(88, 3, 3)">highlight_off</mat-icon>
                    </div>
                </div>
            </form>
        </div>
        <div fxFlex="10" fxLayoutAlign="start end">
            <button mat-raised-button color="primary" (click)="refUpdate(ref.id)" [disabled]="formes.pristine"> Guardar <i class="fas fa-spinner fa-spin" *ngIf="guardando"></i>  </button>
        </div>
        
</mat-card>