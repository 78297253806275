<div class="contenedor">
   
   <mat-grid-list cols="3" rowHeight="1:1">
      <mat-grid-tile *ngFor="let section of sections; let i=index">
         <div class="antcard" >            
            <app-section [section]="section.section" [img]="section.img"></app-section>
         </div>
      </mat-grid-tile>
    </mat-grid-list>
    
   <!-- 

      <hr>
     
   -->
</div>
