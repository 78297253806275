import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rama',
  templateUrl: './rama.component.html',
  styleUrls: ['./rama.component.scss']
})
export class RamaComponent implements OnInit {

  @Input() decretos = [];
  ramas = {};
  _ramas = [];

  constructor() { }

  ngOnInit(): void {
    this.getRamas();
  }

  getRamas(){
    this.decretos.forEach(element => {
      if(!this.ramas[element.COD_RAMA]){this.ramas[element.COD_RAMA] = [];}
      this.ramas[element.COD_RAMA].push(element);
    });
    this._ramas = Object.keys(this.ramas);
  }

}
