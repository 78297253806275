import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EducationsService {

  uriBase = /*'http://localhost:8000/'; // */ 'http://ede.dataeduc.cl/';

  constructor( private http: HttpClient) {
  }
//    Referencias
  public  getRefs(){
    return this.http.get(this.uriBase+ 'api/ref');
  }

  public updateRef(url, id, form){
    return this.http.put(this.uriBase+url+'/'+id, form);
  }

  public getReference(url){
    return this.http.get(this.uriBase+url);
  }

  // tokens 

  public validarToken(otp,rut){
    let rq = {
      rut: rut,
      otp: otp
    }
    return this.http.post(this.uriBase+'api/token', rq);
  }

  // Organizations
  
  public getRbdOrgs(rbd){
    return this.http.get(this.uriBase+'api/colegio/'+rbd+'/orgs');
  }

  // Planificacione   
  

  public getCursos(){
    return this.http.get(this.uriBase+'api/plani/cursos');
  }
  
  public getAsignaturas(curso){
    return this.http.post(this.uriBase+'api/plani/cursos/asignaturas', {curso: curso});
  }
  
  public getPlanis(curso, asignatura){
    return this.http.post(this.uriBase+'api/plani/cursos/asignaturas/unidades', {curso: curso, asignatura: asignatura});
  }

  public updatePlani(id, cambios){
    return this.http.put(this.uriBase+'api/planificacion/'+id, cambios);
  }

  public storePlani(plani){
    return this.http.post(this.uriBase+'api/planificacion/', plani);
  }






}


