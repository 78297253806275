<div class="pinpass">
    <div class="container-fluid">
        <form class="d-flex blos" [formGroup]="fToken">
            <input class="form-control" type="text" placeholder="Clave Aleatorea" aria-label="OTP" formControlName="token">
            <button mat-raised-button color="primary" id="tiempo" (click)="validar()" [disabled]="validando.token">
                <mat-icon>{{validando.icon}}</mat-icon>
                {{validando.time}}
               </button>    
        </form>
    </div>
</div>