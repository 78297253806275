import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EducationsService } from 'src/app/educations.service';

@Component({
  selector: 'app-ref',
  templateUrl: './ref.component.html',
  styleUrls: ['./ref.component.scss']
})
export class RefComponent implements OnInit {

  @Input() ref;
  @Input() uri;
  referencia;
  guardando = false;

  formes: FormGroup;

  constructor(private educations: EducationsService) {
    
   }

  ngOnInit(): void {
    this.referencia = this.ref;
    this.formes = new FormGroup({
      code_es: new FormControl(this.ref.code_es, []),
      description_es: new FormControl(this.ref.description_es, []),
      definition_es: new FormControl(this.ref.definition_es ? this.ref.definition_es : '', [])
    });
  }

  refUpdate(id){
   // console.log(this.formes.value);
   this.guardando = true;
     this.educations.updateRef(this.uri, id, this.formes.value).subscribe((resp: any) => {
       this.formes.markAsPristine();
        this.ref = resp.data;
        this.guardando = false;
    });
  }

}
