import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { NavComponent } from './components/nav/nav.component';
import { AppMaterialModule } from './app-material/app-material.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { SectionComponent } from './components/section/section.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EducationsService } from './educations.service';
import { HttpClientModule } from '@angular/common/http';
import { ReferenciasComponent } from './components/referencias/referencias.component';
import { RouterModule } from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav'; 
import {MatListModule} from '@angular/material/list';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { RefComponent } from './components/referencias/ref/ref.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DecretosComponent } from './components/referencias/decretos/decretos.component';
import { ValidatorComponent } from './components/validator/validator.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { DecretoComponent } from './components/referencias/decretos/decreto/decreto.component';
import { RamaComponent } from './components/referencias/decretos/decreto/rama/rama.component';
import { SectoresComponent } from './components/referencias/decretos/decreto/sectores/sectores.component';
import { EstablecimientosComponent } from './establecimientos/establecimientos/establecimientos.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import { PlanificacionesComponent } from './components/planificaciones/planificaciones.component';
import { CargarPlaniAnualComponent } from './components/planificaciones/planiAnual/cargar-plani-anual/cargar-plani-anual.component';
import {MatTabsModule} from '@angular/material/tabs';
import { EditorComponent } from './components/editor/editor.component';
import { NgxEditorModule } from 'ngx-editor';
import { PlaniAnualComponent } from './components/planificaciones/plani-anual/plani-anual.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { QuillModule } from 'ngx-quill';
import { Delta } from 'quill';
import { Ng2Rut } from 'ng2-rut';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    NavComponent,
    SectionComponent,
    ReferenciasComponent,
    SidenavComponent,
    RefComponent,
    LoginComponent,
    DecretosComponent,
    ValidatorComponent,
    DecretoComponent,
    RamaComponent,
    SectoresComponent,
    EstablecimientosComponent,
    PlanificacionesComponent,
    CargarPlaniAnualComponent,
    EditorComponent,
    PlaniAnualComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    FlexLayoutModule,
    HttpClientModule,
    RouterModule,
    MatListModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatGridListModule,
    MatTabsModule,
    NgxEditorModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    QuillModule.forRoot(),
    Ng2Rut,
    MatCheckboxModule,
    

    
  ],
  providers: [
    EducationsService, SidenavComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
