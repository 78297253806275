import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sectores',
  templateUrl: './sectores.component.html',
  styleUrls: ['./sectores.component.scss']
})
export class SectoresComponent implements OnInit {

  @Input() decretos;
  sectores = {};
  _sectores;

  constructor() { }

  ngOnInit(): void {
    this.getSectores();
    console.log(this._sectores);
  }

  getSectores(){
    this.decretos.forEach(element => {
      if(!this.sectores[element.COD_SECTOR]){this.sectores[element.COD_SECTOR] = [];}
      this.sectores[element.COD_SECTOR].push(element);
    });
    this._sectores = Object.keys(this.sectores);
  }

}
