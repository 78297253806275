<mat-accordion>
      
    <mat-expansion-panel hideToggle *ngFor="let cod of _ramas ">
      <mat-expansion-panel-header>
        <mat-panel-title>
         {{cod}}
        </mat-panel-title>
        <mat-panel-description>
            {{cod != 0 ? ramas[cod][0].COD_RAMA_DESC : "Ciclo General"}}
        </mat-panel-description>
      </mat-expansion-panel-header>
        <app-sectores [decretos]="ramas[0]"></app-sectores>
        
    </mat-expansion-panel>
   
</mat-accordion>