import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { EducationsService } from 'src/app/educations.service';

export interface Decretes {
  id: number;
  description: string;
  cods: object;
}
export interface Codigos {
  Modalidad: string,
  COD_ENSE_AGRUPADO: string,
  COD_ENSE: number,
  COD_ENSE_DESC: string,
  COD_GRADO: number,
  COD_GRADO_DESC: string,
  COD_RAMA: number,
  COD_RAMA_DESC: string,
  COD_SECTOR: number,
  COD_SECTOR_DESC: string,
  COD_ESPECIALIDAD: number,
  COD_ESPECIALIDAD_DESC: string,
}
@Component({
  selector: 'app-decretos',
  templateUrl: './decretos.component.html',
  styleUrls: ['./decretos.component.scss']
})

export class DecretosComponent implements OnInit {

  @Input() uri;
  decretos: Decretes[];
  decreto;
  cods = {
  };
  cod_ens = [];
  displayedColumns: string[] = ['id', 'Description', 'Code', 'COD_ENSE'];
  dataSource;
  panelOpenState = false;
  
  constructor(private educations: EducationsService) {
    
  }
  
  ngOnInit(): void {
    this.getCodigos();
  }


  getCodigos(){
    this.educations.getReference(this.uri).subscribe((decretos: Decretes[]) => {
      this.decretos = decretos;
      decretos.forEach((dec:any) => {
        dec.cods.id = dec.id;
        if(!this.cods[dec.cods.COD_ENSE]){this.cods[dec.cods.COD_ENSE] = [];}
        this.cods[dec.cods.COD_ENSE].push(dec.cods);
      });
      this.cod_ens = Object.keys(this.cods);
    });
  }
  
  selectDecreto(id){
    this.decretos.forEach((decreto: any) => {
      if(decreto.id === id){
        this.decreto = decreto;
      }
    })
  }



}
